<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['customer:group:add']">
          <a-icon type="plus" />新增
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :statusOptions="dict.type.sys_enable_disable"
        :yesNoOptions="dict.type.sys_yes_no_number"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="group_id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
         <span slot="group_status" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_enable_disable']" :value="record.group_status"/>
        </span>
        <span slot="first_service" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_yes_no_number']" :value="record.first_service"/>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['customer:group:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['customer:group:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['customer:group:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {listGroup, delGroup } from '@/api/customerservice/group'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Cancel',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  dicts: ['sys_enable_disable','sys_yes_no_number'],
  data () {
    return {
      list: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      // 查询参数
      queryParam: {
        mobile: null,
        reason: null,
        failReason: null,
        examineName: null,
        examineTime: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '分组名称',
          dataIndex: 'group_name',
          // ellipsis: true,,
          align: 'center'
        },
        {
          title: '分组人数',
          dataIndex: 'group_users',
          // ellipsis: true,,
          align: 'center'
        },
        {
          title: '分组状态',
          dataIndex: 'group_status',
          scopedSlots: { customRender: 'group_status' },
          // ellipsis: true,,
          align: 'center'
        },
        {
          title: '是否前置分组',
          dataIndex: 'first_service',
          scopedSlots: { customRender: 'first_service' },
          // ellipsis: true,,
          align: 'center'
        } ,
        {
          title: '创建时间',
          dataIndex: 'create_time',
          // ellipsis: true,,
          align: 'center'
        },     {
          title: '更新时间',
          dataIndex: 'update_time',
          // ellipsis: true,,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询客服分组列表 */
    getList () {
      this.loading = true
      listGroup().then(response => {
        this.list = response.data
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        mobile: undefined,
        reason: undefined,
        status: undefined,
        failReason: undefined,
        examineName: undefined,
        examineTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const name = row.group_name
      this.$confirm({
        title: '确认删除所选中数据?',
        content:  name,
        onOk () {
          let data = [{"group_id":row.group_id}]
          return delGroup(data).then(response =>{
            if(response){
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            }
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    }
  }
}
</script>
